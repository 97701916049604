import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  public menu = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'About',
      url: '/about',
      icon: 'list'
    },
    {
      title: 'Apply',
      url: '/apply',
      icon: 'add-circle'
    },
    /*
    {
      title: 'Squad and Results',
      url: '/squad',
      icon: 'calendar'
    },
    {
      title: 'Calendar',
      url: '/calendar',
      icon: 'calendar'
    },
    {
      title: 'Gallery',
      url: '/gallery',
      icon: 'images'
    },
    */
    {
      title: 'Sponsors',
      url: '/sponsors',
      icon: 'card'
    },
    {
      title: 'Contact',
      url: '/contact',
      icon: 'mail'
    },
    {
      title: 'Member',
      url: '/admin',
      icon: 'log-in'
    },
    {
      title: 'Tribute',
      url: '/tribute',
      icon: 'heart'
    }
    /*
    {
      title: 'Coaches',
      url: '/coaches',
      icon: 'notifications'
    },
    {
      title: 'Swimmers',
      url: '/swimmers',
      icon: 'man'
    },
    {
      title: 'Committee',
      url: '/committee',
      icon: 'people'
    }
    {
      title: 'News',
      url: '/news',
      icon: 'logo-rss'
    },
    {
      title: 'Records',
      url: '/records',
      icon: 'analytics'
    }*/
  ];

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
