import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import {
  Plugins,
  Capacitor,
  CameraSource,
  CameraResultType
} from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { ImageCroppedEvent } from 'ngx-image-cropper';

function base64toBlob(base64Data) {
  /* contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = window.atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType }); */

  const contentType = '';
  const sliceSize = 512;

  console.log(base64Data);
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit {
  @ViewChild('filePicker') filePickerRef: ElementRef<HTMLInputElement>;
  @Output() imagePick = new EventEmitter<ImageCroppedEvent>();
  @Input() showPreview = false;
  @Input() src: string;
  @Input() aspectRatio: string;
  @Input() backgroundColor: string;
  @Input() resizeToWidth: string;
  @Input() outputType: string;
  selectedImage: string;
  usePicker = false;
  imageFile: File;
  imagePicked64: string;

  constructor(private platform: Platform) {}

  ngOnInit() {
    console.log('Mobile:', this.platform.is('mobile'));
    console.log('Hybrid:', this.platform.is('hybrid'));
    console.log('iOS:', this.platform.is('ios'));
    console.log('Android:', this.platform.is('android'));
    console.log('Desktop:', this.platform.is('desktop'));
    if (
      (this.platform.is('mobile') && !this.platform.is('hybrid')) ||
      this.platform.is('desktop')
    ) {
      this.usePicker = true;
      console.log(this.usePicker);
    }
    if (this.src) {
      this.selectedImage = this.src;
    }
  }

  onPickImage(type) {
    if (type === 'browse') {
      this.filePickerRef.nativeElement.click();
      return;
    } else if (!Capacitor.isPluginAvailable('Camera')) {
      this.filePickerRef.nativeElement.click();
      return;
    }
    Plugins.Camera.getPhoto({
      quality: 50,
      source: CameraSource.Prompt,
      correctOrientation: true,
      // height: 320,
      width: 300,
      resultType: CameraResultType.Base64
    })
      .then((image) => {
        this.selectedImage = 'data:image/jpeg;base64, ' + image.base64String;
        this.showPreview = true;
        console.log(image);
        this.imagePicked64 = image.base64String;
      })
      .catch((error) => {
        console.log(error);
        if (this.usePicker) {
          this.filePickerRef.nativeElement.click();
        }
        return false;
      });
  }

  onFileChosen(event: Event) {
    const pickedFile = (event.target as HTMLInputElement).files[0];
    if (!pickedFile) {
      return;
    }
    const fr = new FileReader();
    fr.onload = () => {
      const dataUrl = fr.result.toString();
      this.selectedImage = dataUrl;
      this.showPreview = true;
      console.log(pickedFile);
      this.imageFile = pickedFile;
    };
    fr.readAsDataURL(pickedFile);
  }

  // image cropper / resizer

  fileChangeEvent(event: any): void {
    console.log('fileChangeEvent()');
    console.log(event);
  }
  imageCropped(event: ImageCroppedEvent) {
    console.log('imageCropped()');
    console.log(event);

    this.imagePick.emit(event);
  }
  imageLoaded() {
    console.log('imageLoaded()');
    // show cropper
  }
  cropperReady() {
    console.log('cropperReady()');
    // cropper ready
  }
  loadImageFailed() {
    console.log('loadImageFailed()');
    // show message
  }
}
