import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByBoolean'
})
export class FilterByBooleanPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    console.log(filter);
    console.log(items);

    if (!items || !filter || filter === 'All') {
      return items;
    }

    return items.filter((item) => {
      if (item.overdue) {
        return true;
      }
    });
  }
}
