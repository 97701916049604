// import * as moment from 'moment';

export function convertSnaps<T>(snaps) {
  return snaps.map(snap => {
    return {
      id: snap.payload.doc.id,
      ...snap.payload.doc.data()
      /*,
            startDate: moment(snap.payload.doc.data().startDate).format('DD-MMM-YYYY'),
            endDate: moment(snap.payload.doc.data().endDate).format('DD-MMM-YYYY'),
            requestDate: moment(snap.payload.doc.data().requestDate).format('DD-MMM-YYYY'),
            */
    };
  }) as T[];
}
