import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDialogModule,
  MatDialogTitle,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';

import { IonicModule } from '@ionic/angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RouterModule } from '@angular/router';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
// https://ngx-gallery.netlify.app/#/lightbox

import { FooterComponent } from '../components/footer/footer.component';
import { ImagePickerComponent } from '../components/image-picker/image-picker.component';
import { UploaderComponent } from '../components/uploader/uploader.component';
import { UploadTaskComponent } from '../components/upload-task/upload-task.component';
import { DropzoneDirective } from '../directives/dropzone.directive';
import { FoldersComponent } from '../components/folders/folders.component';
import { FilterSwimmersPipe } from '../pipes/filter-swimmers.pipe';
import { FilterByStringPipe } from '../pipes/filter-by-string.pipe';
import { NotificationsComponent } from '../components/notifications/notifications.component';
import { FilterByIdPipe } from '../pipes/filter-by-id.pipe';
import { FilterByBooleanPipe } from '../pipes/filter-by-boolean.pipe';

@NgModule({
  declarations: [
    ImagePickerComponent,
    FooterComponent,
    UploaderComponent,
    UploadTaskComponent,
    NotificationsComponent,
    DropzoneDirective,
    FoldersComponent,
    FilterSwimmersPipe,
    FilterByStringPipe,
    FilterByIdPipe,
    FilterByBooleanPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatTabsModule,
    MatTooltipModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatChipsModule,
    MatSelectModule,
    MatDialogModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatRadioModule,
    IonicModule.forRoot(),
    ImageCropperModule,
    RouterModule,
    GalleryModule,
    LightboxModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatTabsModule,
    MatTooltipModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatChipsModule,
    MatSelectModule,
    MatDialogModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatRadioModule,
    ImagePickerComponent,
    ImageCropperModule,
    RouterModule,
    GalleryModule,
    LightboxModule,
    FooterComponent,
    UploaderComponent,
    UploadTaskComponent,
    DropzoneDirective,
    FoldersComponent,
    FilterSwimmersPipe,
    FilterByStringPipe,
    FilterByIdPipe,
    FilterByBooleanPipe,
    NotificationsComponent
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class SharedModule {}
