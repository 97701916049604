import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Folder } from '../../model/folder.model';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  @Input() folder: Folder;
  @Output() newThumbnail = new EventEmitter<string>();

  isHovering: boolean;
  files: File[] = [];

  constructor() {}

  ngOnInit() {}

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }

  addThumbnail(thumbnail: string) {
    this.newThumbnail.emit(thumbnail);
  }
}
