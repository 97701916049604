import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import * as moment from 'moment-timezone';

import { Folder } from '../../model/folder.model';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {
  @Output() newThumbnail = new EventEmitter<string>();

  @Input() file: File;
  @Input() folder: Folder;
  fileResized: File;

  task: AngularFireUploadTask;
  taskThumbnail: AngularFireUploadTask;

  percentage: Observable<number>;
  percentageThumbnail: Observable<number>;

  snapshot: Observable<any>;
  snapshotThumbnail: Observable<any>;

  downloadURL: string;
  thumbnail: string;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore
  ) {}

  ngOnInit() {
    console.log(this.file.type);
    if (this.file.type === 'image/png' || this.file.type === 'image/jpeg') {
      this.resizePhoto();
    } else {
      this.startUploadFile();
    }
  }

  resizePhoto() {
    const file = this.file;
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      let imgResolution = { width: 1280, height: 720 };
      const img = new Image();
      img.onload = () => {
        imgResolution = { width: img.width, height: img.height };
        let newWidth = imgResolution.width;
        let newHeight = imgResolution.height;

        // Thumbnail max 300x200
        if (imgResolution.width > 450 || imgResolution.height > 300) {
          newWidth = (300 / imgResolution.height) * imgResolution.width;
          newHeight = 300;
        }
        this.compressImage(reader.result, newWidth, newHeight).then(
          (compressed64: string) => {
            this.startUploadPhoto(compressed64, true);
          }
        );

        // Full img max 1280x720
        if (imgResolution.width > 1280 || imgResolution.height > 720) {
          newWidth = (720 / imgResolution.height) * imgResolution.width;
          newHeight = 720;
        }
        this.compressImage(reader.result, newWidth, newHeight).then(
          (compressed64: string) => {
            this.startUploadPhoto(compressed64, false);
          }
        );
      };
      img.src = reader.result as string; // is the data URL because called with readAsDataURL
    };
  }

  compressImage(src: string | ArrayBuffer, newX: number, newY: number) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src as string;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL('image/jpeg');
        res(data);
      };
      img.onerror = (error) => rej(error);
    });
  }

  async startUploadPhoto(base64: string, thumbnail: boolean) {
    if (thumbnail) {
      // The storage path
      const path = `photos-thumbnails/${Date.now()}_${
        this.file.name
      }_thumbnail`;

      // Reference to storage bucket
      const ref = this.storage.ref(path);

      // The main task
      await ref.putString(base64, 'data_url');
      this.thumbnail = await ref.getDownloadURL().toPromise();
      this.uploadPhoto();
    } else {
      // The storage path
      const path = `photos/${Date.now()}_${this.file.name}`;

      // Reference to storage bucket
      const ref = this.storage.ref(path);

      // The main task
      this.task = ref.putString(base64, 'data_url');

      // Progress monitoring
      this.percentage = this.task.percentageChanges();

      this.snapshot = this.task.snapshotChanges().pipe(
        // tap(console.log),
        // The file's download URL
        finalize(async () => {
          this.downloadURL = await ref.getDownloadURL().toPromise();
          this.uploadPhoto();
        })
      );
    }
  }

  uploadPhoto() {
    if (this.thumbnail && this.downloadURL) {
      this.db
        .collection('photos')
        .add({
          url: this.downloadURL,
          thumbnail: this.thumbnail,
          folder: this.folder,
          fileName: this.file.name,
          fileType: this.file.type,
          year: this.folder.year,
          deleted: false,
          created: {
            date: firebase.firestore.FieldValue.serverTimestamp(),
            by: 'admin',
            dateFormat: moment.tz('Indian/Mauritius').format('DD-MM-YYYY')
          },
          lastModified: {
            date: firebase.firestore.FieldValue.serverTimestamp(),
            by: 'admin',
            dateFormat: moment.tz('Indian/Mauritius').format('DD-MM-YYYY')
          }
        })
        .then(() => {
          this.newThumbnail.emit(this.thumbnail);
        });
    }
  }

  async startUploadFile() {
    // The storage path
    const path = `files/${Date.now()}_${this.file.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      // tap(console.log),
      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.uploadFile();
      })
    );
  }

  uploadFile() {
    this.db
      .collection('files')
      .add({
        url: this.downloadURL,
        folder: this.folder,
        fileName: this.file.name,
        fileType: this.file.type,
        year: this.folder.year,
        deleted: false,
        created: {
          date: firebase.firestore.FieldValue.serverTimestamp(),
          by: 'admin',
          dateFormat: moment.tz('Indian/Mauritius').format('DD-MM-YYYY')
        },
        lastModified: {
          date: firebase.firestore.FieldValue.serverTimestamp(),
          by: 'admin',
          dateFormat: moment.tz('Indian/Mauritius').format('DD-MM-YYYY')
        }
      })
      .then(() => {
        this.newThumbnail.emit(this.thumbnail);
      });
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}
