import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterById'
})
export class FilterByIdPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    console.log(filter);
    console.log(items);

    if (!items || !filter || filter === 'All') {
      return items;
    }

    const filterValue = filter.toLowerCase();

    return items.filter((item) => {
      if (
        item.group &&
        item.group.groupId &&
        item.group.groupId.toLowerCase().includes(filterValue)
      ) {
        return true;
      }
    });
  }
}
