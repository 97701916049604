import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import * as moment from 'moment-timezone';

import { Folder } from '../model/folder.model';
import { UIService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class FoldersService {
  constructor(private db: AngularFirestore) {}

  addFolder(folder: Folder) {
    return this.db.collection<Folder>(`folders`).add({
      name: folder.name,
      year: folder.year,
      created: {
        date: firebase.firestore.FieldValue.serverTimestamp(),
        by: 'admin',
        dateFormat: moment.tz('Indian/Mauritius').format('DD-MM-YYYY')
      },
      lastModified: {
        date: firebase.firestore.FieldValue.serverTimestamp(),
        by: 'admin',
        dateFormat: moment.tz('Indian/Mauritius').format('DD-MM-YYYY')
      }
    });
  }

  getFolders() {
    return this.db
      .collection<any>(`folders`, (ref: any) => ref.orderBy('year'))
      .valueChanges({ idField: 'folderId' });
  }

  getFolder(year: number) {
    return this.db
      .collection<any>(`folders`, (ref: any) =>
        ref.where('year', '==', year).orderBy('name')
      )
      .valueChanges({ idField: 'folderId' });
  }
}
