import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { NotificationsService } from '../../services/notifications.service';
import { Notification } from '../../model/notification.model';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications$: Observable<Notification[]>;
  closeMessage = false;

  constructor(
    private notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<NotificationsComponent>
  ) {}

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    this.notifications$ = this.notificationsService.getNotifications();
    console.log(this.notifications$);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
