import { Pipe, PipeTransform } from '@angular/core';
import { Swimmer } from '../model/swimmer.model';

@Pipe({
  name: 'filterSwimmers'
})
export class FilterSwimmersPipe implements PipeTransform {
  transform(swimmers: Swimmer[], filter: string): any {
    if (!swimmers || !filter) {
      return swimmers;
    }

    function checkName(item: any) {
      const filterValue = filter.toLowerCase();
      item.nameFr.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      return (
        item.firstname
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(filterValue) ||
        item.lastname
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(filterValue)
      );
    }

    return swimmers.filter(checkName);
  }
}
