import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'apply', loadChildren: './pages/apply/apply.module#ApplyPageModule' },
  {
    path: 'sponsors',
    loadChildren: './pages/sponsors/sponsors.module#SponsorsPageModule'
  },
  {
    path: 'gallery',
    loadChildren: './pages/gallery/gallery.module#GalleryPageModule'
  },
  {
    path: 'records',
    loadChildren: './pages/records/records.module#RecordsPageModule'
  },
  {
    path: 'contact',
    loadChildren: './pages/contact/contact.module#ContactPageModule'
  },
  {
    path: 'admin-notifications',
    loadChildren:
      './pages/admin/admin-notifications/admin-notifications.module#AdminNotificationsPageModule'
  },
  {
    path: 'admin-photos',
    loadChildren:
      './pages/admin/admin-photos/admin-photos.module#AdminPhotosPageModule'
  },
  {
    path: 'admin-records',
    loadChildren:
      './pages/admin/admin-records/admin-records.module#AdminRecordsPageModule'
  },
  {
    path: 'admin-events',
    loadChildren:
      './pages/admin/admin-events/admin-events.module#AdminEventsPageModule'
  },
  {
    path: 'admin-events-add',
    loadChildren:
      './pages/admin/admin-events/admin-events-add/admin-events-add.module#AdminEventsAddPageModule'
  },
  {
    path: 'admin-events-edit',
    loadChildren:
      './pages/admin/admin-events/admin-events-edit/admin-events-edit.module#AdminEventsEditPageModule'
  },
  {
    path: 'admin-events-list',
    loadChildren:
      './pages/admin/admin-events/admin-events-list/admin-events-list.module#AdminEventsListPageModule'
  },
  {
    path: 'admin-events-detail',
    loadChildren:
      './pages/admin/admin-events/admin-events-detail/admin-events-detail.module#AdminEventsDetailPageModule'
  },
  {
    path: 'calendar',
    loadChildren: './pages/calendar/calendar.module#CalendarPageModule'
  },
  {
    path: 'admin-applications',
    loadChildren:
      './pages/admin/admin-applications/admin-applications.module#AdminApplicationsPageModule'
  },
  {
    path: 'admin-applications-list',
    loadChildren:
      './pages/admin/admin-applications/admin-applications-list/admin-applications-list.module#AdminApplicationsListPageModule'
  },
  {
    path: 'admin-applications-detail/:applicationId',
    loadChildren:
      './pages/admin/admin-applications/admin-applications-detail/admin-applications-detail.module#AdminApplicationsDetailPageModule'
  },
  { path: 'admin', loadChildren: './pages/admin/admin.module#AdminPageModule' },
  {
    path: 'apply-success',
    loadChildren:
      './pages/apply/apply-success/apply-success.module#ApplySuccessPageModule'
  },
  {
    path: 'contact-success',
    loadChildren:
      './pages/contact/contact-success/contact-success.module#ContactSuccessPageModule'
  },
  {
    path: 'admin-notifications-add',
    loadChildren:
      './pages/admin/admin-notifications/admin-notifications-add/admin-notifications-add.module#AdminNotificationsAddPageModule'
  },
  {
    path: 'admin-notifications-list',
    loadChildren:
      './pages/admin/admin-notifications/admin-notifications-list/admin-notifications-list.module#AdminNotificationsListPageModule'
  },
  { path: 'squad', loadChildren: './pages/squad/squad.module#SquadPageModule' },
  {
    path: 'tribute',
    loadChildren: './pages/tribute/tribute.module#TributePageModule'
  },
  {
    path: 'coaches',
    loadChildren: './pages/coaches/coaches.module#CoachesPageModule'
  },
  {
    path: 'swimmers',
    loadChildren: './pages/swimmers/swimmers.module#SwimmersPageModule'
  },
  {
    path: 'committee',
    loadChildren: './pages/committee/committee.module#CommitteePageModule'
  },
  {
    path: 'admin-swimmers',
    loadChildren: () =>
      import('./pages/admin/admin-swimmers/admin-swimmers.module').then(
        (m) => m.AdminSwimmersPageModule
      )
  },
  {
    path: 'admin-swimmers-list',
    loadChildren: () =>
      import(
        './pages/admin/admin-swimmers-list/admin-swimmers-list.module'
      ).then((m) => m.AdminSwimmersListPageModule)
  },
  {
    path: 'admin-swimmers-details',
    loadChildren: () =>
      import(
        './pages/admin/admin-swimmers-details/admin-swimmers-details.module'
      ).then((m) => m.AdminSwimmersDetailsPageModule)
  },
  {
    path: 'admin-members-details',
    loadChildren: () =>
      import(
        './pages/admin/admin-members-details/admin-members-details.module'
      ).then((m) => m.AdminMembersDetailsPageModule)
  },
  {
    path: 'admin-members-list',
    loadChildren: () =>
      import('./pages/admin/admin-members-list/admin-members-list.module').then(
        (m) => m.AdminMembersListPageModule
      )
  },
  {
    path: 'admin-members-new',
    loadChildren: () =>
      import('./pages/admin/admin-members-new/admin-members-new.module').then(
        (m) => m.AdminMembersNewPageModule
      )
  },
  {
    path: 'admin-groups-new',
    loadChildren: () =>
      import('./pages/admin/admin-groups-new/admin-groups-new.module').then(
        (m) => m.AdminGroupsNewPageModule
      )
  },
  {
    path: 'admin-groups-list',
    loadChildren: () =>
      import('./pages/admin/admin-groups-list/admin-groups-list.module').then(
        (m) => m.AdminGroupsListPageModule
      )
  },
  {
    path: 'admin-groups-details',
    loadChildren: () =>
      import(
        './pages/admin/admin-groups-details/admin-groups-details.module'
      ).then((m) => m.AdminGroupsDetailsPageModule)
  },
  {
    path: 'admin-coaches-details',
    loadChildren: () =>
      import(
        './pages/admin/admin-coaches-details/admin-coaches-details.module'
      ).then((m) => m.AdminCoachesDetailsPageModule)
  },
  {
    path: 'admin-coaches-list',
    loadChildren: () =>
      import('./pages/admin/admin-coaches-list/admin-coaches-list.module').then(
        (m) => m.AdminCoachesListPageModule
      )
  },
  {
    path: 'admin-coaches-new',
    loadChildren: () =>
      import('./pages/admin/admin-coaches-new/admin-coaches-new.module').then(
        (m) => m.AdminCoachesNewPageModule
      )
  },
  {
    path: 'admin-fee-new',
    loadChildren: () =>
      import('./pages/admin/admin-fee-new/admin-fee-new.module').then(
        (m) => m.AdminFeeNewPageModule
      )
  },
  {
    path: 'admin-fee-list',
    loadChildren: () =>
      import('./pages/admin/admin-fee-list/admin-fee-list.module').then(
        (m) => m.AdminFeeListPageModule
      )
  },
  {
    path: 'admin-fee-details',
    loadChildren: () =>
      import('./pages/admin/admin-fee-details/admin-fee-details.module').then(
        (m) => m.AdminFeeDetailsPageModule
      )
  },
  {
    path: 'admin-swimmers-payments-list',
    loadChildren: () =>
      import(
        './pages/admin/admin-swimmers-payments-list/admin-swimmers-payments-list.module'
      ).then((m) => m.AdminSwimmersPaymentsListPageModule)
  },  {
    path: 'admin-members-payments-list',
    loadChildren: () => import('./pages/admin/admin-members-payments-list/admin-members-payments-list.module').then( m => m.AdminMembersPaymentsListPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
