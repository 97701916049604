import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByString'
})
export class FilterByStringPipe implements PipeTransform {
  /* transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  } */

  transform(items: any[], filter: any): any {
    console.log(filter);
    console.log(items);

    if (!items || !filter) {
      return items;
    }

    const filterValue = filter.toLowerCase();

    return items.filter((item) => {
      if (
        item.firstName.toLowerCase().includes(filterValue) ||
        item.lastName.toLowerCase().includes(filterValue)
      ) {
        return true;
      }
    });
  }
}
