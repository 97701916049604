import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';

import { Folder } from '../../model/folder.model';
import { FoldersService } from '../../services/folders.service';
import { UIService } from '../../services/ui.service';

@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit {
  folderForm: FormGroup;
  folders: Folder[];

  years: number[] = [1900];
  selectedYear: number = Number(moment().format('YYYY'));

  @Output() selectedFolder = new EventEmitter<Folder>();

  constructor(
    private uiService: UIService,
    private foldersService: FoldersService
  ) {}

  ngOnInit() {
    this.folderForm = new FormGroup({
      name: new FormControl(null, {
        updateOn: 'blur',
        validators: [Validators.required]
      }),

      year: new FormControl(null, {
        updateOn: 'blur',
        validators: [Validators.required]
      })
    });

    this.getYears();
    // this.getFolders();
    this.setYear(this.selectedYear);
  }

  getYears() {
    const firstYear = 2000;
    const lastYear = Number(moment().add(1, 'year').format('YYYY'));
    const folderCount = lastYear - firstYear;

    for (let i = 0; i < folderCount + 1; i++) {
      const year = 2000 + i;
      this.years.push(year);
    }

    this.years.sort((a, b) => {
      return b - a;
    });

    console.log(this.years);
  }

  addFolder() {
    this.foldersService
      .addFolder(this.folderForm.value)
      .then((response) => {
        console.log(response);
        this.uiService.loadingStateChanged.next(false);
        this.uiService.showSnackbar('Item created!', null, 5000);
      })
      .catch((error) => {
        console.log(error);
        this.uiService.loadingStateChanged.next(false);
        this.uiService.showSnackbar(error.message, null, 5000);
      });
  }

  getFolders() {
    this.foldersService.getFolders().subscribe((folders) => {
      console.log(folders);
      this.folders = folders;
    });
  }

  getFolder(year: number) {
    this.foldersService.getFolder(year).subscribe((folders) => {
      console.log(folders);
      this.folders = folders;
    });
  }

  setFolder(folder: Folder) {
    console.log(folder);
    this.selectedFolder.emit(folder);
    this.folders.forEach((f) => {
      f.selected = false;
      if (f.folderId === folder.folderId) {
        f.selected = true;
      }
    });
  }

  setYear(year: number) {
    this.folderForm.patchValue({ year });
    this.getFolder(year);
    this.selectedFolder.emit(null);
  }
}
