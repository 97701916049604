import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
// import 'firebase/firestore';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';

import { Notification } from '../model/notification.model';
import { convertSnaps } from './db-utils';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private router: Router, private db: AngularFirestore) {}

  async addNotification(data: any) {
    data.addedBy = 'Admin';
    data.lastModified = firebase.firestore.FieldValue.serverTimestamp();
    await this.db.collection('notifications').add(data);
    this.router.navigateByUrl('/home');
  }

  getNotifications(): Observable<Notification[]> {
    return this.db
      .collection('notifications')
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          // Add document id
          let notifications = convertSnaps<Notification>(snaps);

          // Sort
          notifications = notifications.sort(
            (a: any, b: any) =>
              moment(b.lastModified.toDate()).valueOf() -
              moment(a.lastModified.toDate()).valueOf()
          );

          // Convert server timestamp to MRU time
          notifications.forEach((notification) => {
            notification.lastModified = moment(
              notification.lastModified.toDate()
            )
              .tz('Indian/Mauritius')
              .format('dddd Do MMM YYYY HH:mm');
          });

          console.log(notifications);
          return notifications;
        })
      );
  }

  async deleteNotification(notificationId: string) {
    console.log(notificationId);
    await this.db.collection('notifications').doc(notificationId).delete();
    // this.router.navigateByUrl('/admin-notifications');
  }
}
